import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCoverflow } from "swiper/modules";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import "./App.css";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-coverflow";

ReactGA.initialize("G-XXXXXXXXXX"); // Replace with your actual Measurement ID

const images = [
  "images/Deborah/18.jpg",
  "images/Deborah/2.jpg",
  "images/Deborah/15.jpg",
  "images/Deborah/9.jpg",
  "images/Deborah/25.jpg",
  "images/Deborah/4.jpg",
  "images/Deborah/20.jpg",
  "images/Deborah/12.jpg",
  "images/Deborah/8.jpg",
  "images/Deborah/22.jpg",
  "images/Deborah/7.jpg",
  "images/Deborah/24.jpg",
  "images/Deborah/10.jpg",
];

const buttonData = [
  { title: "Onlyfans", link: "http://onlyfans.com/deborahssecret", icon: "images/buttonlogo/onlyfans.png" },
  { title: "BestFans", link: "http://www.bestfans.com/deborahssecret", icon: "images/buttonlogo/bestfans.png" },
  { title: "4based", link: "https://4based.com/profile/deborahssecret", icon: "images/buttonlogo/4based.png" },
  { title: "MALOUM", link: "https://app.maloum.com/creator/deborahssecret", icon: "images/buttonlogo/maloum.png" },
  { title: "Snapsell", link: "https://snapsell.info/os3HSQw81O", icon: "images/buttonlogo/snapsell.png" },
  { title: "MYM", link: "https://mym.fans/Deborahssecret", icon: "images/buttonlogo/mym.png" },
  { title: "Telegram", link: "https://t.me/Deborahssecretvip", icon: "images/buttonlogo/telegram.png" },
  { title: "Amazon Wishlist", link: "https://www.amazon.de/hz/wishlist/ls/231QGBHM7YHDN?ref_=wl_share", icon: "images/buttonlogo/amazon.png" },
  { title: "WhatsApp (Fanblast)", link: "https://fanblast.com/debby/content/pry1lln6", icon: "images/buttonlogo/fanblast.png" },
  { title: "Instagram", link: "https://www.instagram.com/deborahssecret/profilecard/?igsh=NnBqNXltMzYyazB0", icon: "images/buttonlogo/instagram.png" },
  { title: "TikTok", link: "https://www.tiktok.com/@deborahssecret?_t=8qovaJjugYD&_r=1", icon: "images/buttonlogo/tiktok.png" },
  { title: "X (Twitter)", link: "https://twitter.com/deborahssecret", icon: "images/buttonlogo/x.png" },
];

const App = () => {
  const [location, setLocation] = useState({
    city: "",
    countryCode: "",
    flag: "",
  });

  const handleButtonClick = (title) => {
    ReactGA.event({
      category: "User",
      action: `Clicked ${title} button`,
      label: "Hero Banner",
      value: "value",
    });
  };

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await axios.get("https://ipinfo.io?token=0d37bd38f2037e");
        setLocation({
          city: response.data.city,
          countryCode: response.data.country,
          flag: `https://flagcdn.com/w40/${response.data.country.toLowerCase()}.png`,
        });
      } catch (error) {
        console.error("Error fetching location data:", error);
      }
    };

    fetchLocation();
  }, []);

  return (
    <div className="container">
      <Analytics />
      <SpeedInsights />

      <div>
        {/* Title with Verona font */}
        <p className="title">
  <strong>
    <span>Deborah's Secret</span>
  </strong>
</p>


        <div style={{ maxWidth: "500px", width: "100%", margin: "0px auto" }}>
          <Swiper
            modules={[Autoplay, EffectCoverflow]}
            effect="coverflow"
            coverflowEffect={{
              rotate: 30,
              slideShadows: false,
              stretch: 0,
              depth: 400,
              setWrapperSize: false,
            }}
            centeredSlides
            spaceBetween={0}
            autoplay
            slidesPerView={3}
            loop
            loopAdditionalSlides={2}
          >
            {images.map((src, index) => (
              <SwiperSlide key={index}>
                <img src={src} alt={`Slide ${index}`} className="slide-image" style={{ display: "block", width: "100%", borderRadius: "12px" }} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="textContainer">
          <div className="detailsText">
            {/* Age and zodiac symbol */}
            <div style={{ marginTop: "20px", marginBottom: "10px" }}>💖 24 years ♑︎ Capricorn</div>

            {/* Online Now section */}
            <div className="onlineText" style={{ marginBottom: "10px", marginTop: "10px" }}>
              🟢 Online Now
            </div>

            {/* Direct content message */}
            <p style={{ marginBottom: "10px" }}>
              Get exclusive access to my content and interact with me directly.
            </p>
          </div>
        </div>

        {/* Button Container with 12 Independent Buttons in a Single Column */}
        <div className="buttonContainer">
          {buttonData.map((button, index) => (
            <a
              href={button.link}
              id="customButton"
              key={index}
              onClick={() => handleButtonClick(button.title)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>{button.title}</span>
              <img src={button.icon} alt={`${button.title} icon`} className="buttonIcon" />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default App;
